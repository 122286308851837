import { useToast } from '@chakra-ui/react';
import { pick } from 'lodash-es';
import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { determineEnv } from '@lon/shared/constants';
import { FrostContentPlayerContext } from '@lon/shared/contexts';
import { frostJSON } from '@lon/shared/mocks';
import { FrostMetaData, ScopeMetaObj, ScopeObj } from '@lon/shared/types';

export const getFrostAuthenticationURL = () => {
  switch (determineEnv()) {
    case 'dev':
      return 'https://unifiedfrost-dev.learningmate.co/frost/api/laravel/public/api/v1/get-tree-toc-with-metadata-s3path';

    case 'release':
      return 'https://unifiedfrost-qa.learningmate.co/frost/api/laravel/public/api/v1/get-tree-toc-with-metadata-s3path';

    case 'main':
      return 'https://unifiedfrost-stage.learningmate.co/frost/api/laravel/public/api/v1/get-tree-toc-with-metadata-s3path'; // Frost stage Beta

    case 'uat':
      return 'https://stage.learningmate.in/frost/api/laravel/public/api/v1/get-tree-toc-with-metadata-s3path'; // Frost stage Alpha

    default:
      return 'https://frostplatform.com/frost/api/laravel/public/api/v1/get-tree-toc-with-metadata-s3path';
  }
};

export const parseList = (frostJSONData: any) => {
  const collection: ScopeObj[] = [];
  const home = frostJSONData?.data?.children[0]?.children.find(
    (ch: any) => ch.title === 'Home'
  );
  const image = frostJSONData?.data?.children[0]?.children.find(
    (ch: any) => ch.title === 'Scope Image'
  );

  home?.children.forEach((state: any) => {
    collection.push({
      id: state.id,
      ...pick(kvpMetaToObj((state.meta_data as FrostMetaData)['kvp']), [
        'displayName',
        'editionName',
      ]),
      image: image?.children[0].singned_url as string,
      metadata: kvpMetaToObj((state.meta_data as FrostMetaData)['kvp']),
      projectId: frostJSONData?.data?.project_id,
    });
  });

  return {
    scopes: {
      collection,
      pageInfo: {
        totalCount: collection.length,
        __typename: 'PageInfo',
      },
    },
  };
};

export const parseGrades = (frostData: any) => {
  const home = frostData.data.children[0].children.find(
    (ch: any) => ch.title === 'Home'
  );
  const grade = kvpMetaToObj(
    (home?.children[0].meta_data as FrostMetaData)['kvp']
  )['gradeLevel'];

  return {
    grades: {
      grades: grade ? [grade] : [],
      __typename: 'Grades',
    },
  };
};

export const parseCurriculumAreas = (frostData: any) => {
  const home = frostData.data.children[0].children.find(
    (ch: any) => ch.title === 'Home'
  );
  const ca = kvpMetaToObj(
    (home?.children[0].meta_data as FrostMetaData)['kvp']
  )['curriculumArea'];

  return {
    curriculumAreas: {
      curriculumAreas: ca ? [ca] : [],
      __typename: 'Grades',
    },
  };
};

export const parseTopics = (frostData: any) => {
  const home = frostData.data.children[0].children.find(
    (ch: any) => ch.title === 'Home'
  );
  const topics = kvpMetaToObj(
    (home?.children[0].meta_data as FrostMetaData)['kvp']
  )['topic'];

  return {
    topics: {
      topics: topics ? topics : [],
      __typename: 'Grades',
    },
  };
};

const kvpMetaToObj = (kvp: FrostMetaData['kvp']) => {
  const kvpObj: ScopeMetaObj = {};

  const contentGroups: string[] = [];
  const topics: string[] = [];
  kvp?.forEach((k) => {
    if (camelize(k.key_name) === 'contentGroup') {
      contentGroups.push(k.value);
    }
    if (camelize(k.key_name) === 'topic') {
      topics.push(k.value);
    }

    kvpObj[camelize(k.key_name)] = k.value;
  });

  kvpObj['contentGroup'] = contentGroups;
  kvpObj['topic'] = topics;

  return kvpObj;
};

function camelize(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word: string, index: number) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export const useGetFrostDataParsed = () => {
  const [data, setData] = useState<any>({
    scopes: {
      collection: [],
      pageInfo: {
        totalCount: 0,
        __typename: 'PageInfo',
      },
    },
  });

  const { setFrostData, setLoading, loading, frostData } = useContext(
    FrostContentPlayerContext
  );
  const { token, tenantId, projectId } = useGetFrostParams();
  const toast = useToast();

  const apiCall = async () => {
    if (!frostData) {
      setLoading(true);

      if (!token) {
        toast({
          title: 'Token not found or Token expired.',
          isClosable: true,
          duration: null,
          variant: 'error',
        });

        setLoading(false);
        return;
      }

      if (!projectId || !tenantId) {
        toast({
          title: 'Project/Tenant not found. Please contact Admin.',
          isClosable: true,
          duration: null,
          variant: 'error',
        });

        setLoading(false);
        return;
      }

      const frostDomainFromLocalStorage = localStorage.getItem('frostDomain');
      const contentUrl = frostDomainFromLocalStorage
        ? frostDomainFromLocalStorage +
          `/frost/api/laravel/public/api/v1/get-tree-toc-with-metadata-s3path`
        : getFrostAuthenticationURL();
      const formData = new FormData();
      formData.append('tenant_id', `${tenantId}`);
      formData.append('project_id', `${projectId}`);
      fetch(contentUrl, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      })
        .then((resp) => resp.json())
        .then((result) => {
          if (result.unauthorized_access) {
            toast({
              title: result.error?.message,
              isClosable: true,
              duration: null,
              variant: 'error',
            });
            setLoading(false);

            return;
          }

          if (!result.data?.children) {
            toast({
              title: result.message,
              isClosable: true,
              duration: null,
              variant: 'error',
            });
            setLoading(false);
            return;
          }

          setData(parseList(result));
          setFrostData(result);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          toast({
            title: 'Something went wrong!',
            isClosable: true,
            duration: null,
            variant: 'error',
          });
        });
    }
  };

  useEffect(() => {
    console.log('API CALL', token);

    if (token) {
      apiCall();
    }
  }, [token, tenantId, projectId]);

  useEffect(() => {
    if (frostData) {
      setData(parseList(frostData));
    }
  }, [frostData]);

  return { data, loading, refetch: apiCall };
};

export const useGetFrostGradeDataParsed = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({
    grades: {
      grades: [],
    },
  });

  const apiCall = () => {
    setLoading(true);
    setTimeout(() => {
      setData(parseGrades(frostJSON));
      setLoading(false);
    }, 1800);
  };

  useEffect(() => {
    apiCall();
  }, []);

  return { data, loading, refetch: apiCall };
};

export const useGetFrostCurriculumAreasDataParsed = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({
    curriculumAreas: {
      curriculumAreas: [],
    },
  });

  const apiCall = () => {
    setLoading(true);
    setTimeout(() => {
      setData(parseCurriculumAreas(frostJSON));
      setLoading(false);
    }, 1800);
  };

  useEffect(() => {
    apiCall();
  }, []);

  return { data, loading, refetch: apiCall };
};

export const useGetFrostTopicsDataParsed = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>({
    topics: {
      topics: [],
    },
  });

  const apiCall = () => {
    setLoading(true);
    setTimeout(() => {
      setData(parseTopics(frostJSON));
      setLoading(false);
    }, 1800);
  };

  useEffect(() => {
    apiCall();
  }, []);

  return { data, loading, refetch: apiCall };
};

const useGetFrostParams = () => {
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [projectId, setProjectId] = useState('');

  useEffect(() => {
    if (
      searchParams.get('token') &&
      searchParams.get('tenant_id') &&
      searchParams.get('project_id')
    ) {
      setToken(searchParams.get('token') || '');
      setTenantId(searchParams.get('tenant_id') || '');
      setProjectId(searchParams.get('project_id') || '');
    } else {
      setToken(localStorage.getItem('@frostToken') || '');
      setTenantId(localStorage.getItem('@frostTenantId') || '');
      setProjectId(localStorage.getItem('@frostProjectId') || '');
    }
  }, [searchParams]);

  useEffect(() => {
    if (token && tenantId && projectId) {
      localStorage.setItem('@frostToken', token);
      localStorage.setItem('@frostProjectId', projectId);
      localStorage.setItem('@frostTenantId', tenantId);
    }
  }, [token, tenantId, projectId]);

  return {
    queryString: `?token=${token}&tenant_id=${tenantId}&project_id=${projectId}`,
    token,
    tenantId,
    projectId,
  };
};
