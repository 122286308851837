import { Flex, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Select } from '@lon/shared/components';
import { useQueryParams } from '@lon/shared/hooks';
import { LevelEnumType } from '@lon/shared/requests';

const StandardsList = () => {
  const { t } = useTranslation();
  const [params, setParams] = useQueryParams<{
    level: string;
  }>();
  const level = params.get<string>('level') || '';
  const [value, setValue] = useState({
    value: level,
    label: level.replace(/(\D)(\d)/, '$1 $2'),
  });

  const options = Object.keys(LevelEnumType).map((key) => ({
    label: key.replace(/(\D)(\d)/, '$1 $2'),
    value: key,
  }));

  useEffect(() => {
    if (level !== value.value) {
      setValue({ value: level, label: level.replace(/(\D)(\d)/, '$1 $2') });
    }
  }, [level, value.value]);

  return (
    <Flex p={6} gap={4} background="white" alignItems="center">
      <Text
        id="selectLabel"
        as="label"
        variant="h5"
        lineHeight="1.625rem"
        ml={8}
        color="primary.800"
        flexShrink={0}
      >
        {t('reportsDashboard.standardsLevel')}
      </Text>
      <Select
        isLoading={!level}
        aria-labelledby="selectLabel"
        name="standards"
        placeholder="Select Standard"
        value={value}
        onChange={(item) => {
          if (!item) {
            return;
          }

          setValue(item);
          setParams(
            {
              ...params,
              level: item?.value as string,
            },
            { replace: true }
          );
        }}
        options={options}
      />
    </Flex>
  );
};

export default StandardsList;
