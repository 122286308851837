import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RootState } from '@lon/shared/configs';
import {
  FontFamilyEnum,
  FontSizeEnum,
  fontFamilyMapping,
  fontSizesMapping,
  isDevMode,
} from '@lon/shared/constants';
import { teacherView } from '@lon/shared/constants';
import { useQueryParams } from '@lon/shared/hooks';
import { ViewType } from '@lon/shared/types';
import { checkContentPlayer, parseJSON } from '@lon/shared/utils';
import { types } from './duck';
import { Skeleton } from './components';
import styles from './ElementContent.module.css';

const ElementContent: React.FC<types.ElementPartsProps> = ({
  element,
  iframeRef,
}) => {
  const [params] = useQueryParams();
  const { scopeId } = useParams();
  const isContentPlayer = checkContentPlayer();

  const applicationSettings = useSelector(
    (state: RootState) => state.applicationSettings
  );
  let fontSize: FontSizeEnum = FontSizeEnum.default;
  let fontFamily: FontFamilyEnum = FontFamilyEnum.verdana;

  const viewType: ViewType = params.type || teacherView;
  const key = `${element.identifier}-${viewType}`;

  const preferences = applicationSettings?.preferences;
  if (preferences) {
    fontSize = (parseJSON(preferences) as any)?.fontSize;
    fontFamily = (parseJSON(preferences) as any)?.fontFamily;
  }

  useEffect(() => {
    const handleMessage = (message: any) => {
      if (
        iframeRef?.current &&
        message?.data?.type === 'resizeContent' &&
        message.data.payload.height
      ) {
        // TODO Remove extra 20px when the best solution is found
        iframeRef.current.height = message.data.payload.height + 20;
      }

      if (
        !isDevMode() &&
        iframeRef?.current &&
        message?.data?.type === 'dynamicWidthHeightCalculation' &&
        message?.data?.elemHeight &&
        message?.data?.dataPath
      ) {
        const tabTableFrame: HTMLIFrameElement | undefined | null =
          iframeRef.current.contentWindow?.document.querySelector(
            `[data-path="${message.data.dataPath}"]`
          );

        if (tabTableFrame) {
          const urlParams = new URLSearchParams(window.parent.location.search);
          const ttsEnabled = urlParams.get('tts') === 'ON';

          tabTableFrame.scrolling = 'no';
          tabTableFrame.height = ttsEnabled
            ? message.data.elemHeight + 53
            : message.data.elemHeight;
        }
      }
    };
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, []);

  useEffect(() => {
    const handleMessage = (message: any) => {
      if (message?.data.type === 'page-not-found') {
        try {
          const scope404 = JSON.parse(
            window.sessionStorage.getItem('scope-404') || '[]'
          );
          const alreadyTriedToReload = scope404?.includes(scopeId);

          if (!alreadyTriedToReload) {
            window.sessionStorage.setItem(
              'scope-404',
              JSON.stringify([...scope404, scopeId])
            );
            window.location.reload();
          }
        } catch (error) {
          console.error(error);
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, [scopeId]);

  useEffect(() => {
    const body = iframeRef?.current?.contentWindow?.document.body;
    if (body && fontFamily && fontSize) {
      (body?.parentNode as any).style?.setProperty(
        'font-size',
        fontSizesMapping?.[fontFamily]?.[fontSize]?.fontSize,
        'important'
      );
      body.style.fontFamily = fontFamilyMapping[fontFamily];
      body.style.fontSize =
        fontSizesMapping?.[fontFamily]?.[fontSize]?.fontSize;
      body.style.lineHeight =
        fontSizesMapping?.[fontFamily]?.[fontSize]?.lineHeight;
    }
  }, [fontSize, fontFamily]);

  useEffect(() => {
    // Inject content-manager.js and non-lti-global_lms.js scripts for content player iframe
    if (!isContentPlayer) {
      return;
    }
    const handleMessage = (message: any) => {
      if (message.data.actionCode === 'CONTENT_READY' && iframeRef?.current) {
        const baseUrl = isDevMode()
          ? `https://${window.location.hostname.replace('local.', '')}`
          : `https://${window.location.hostname}`;
        iframeRef.current.contentWindow?.postMessage(
          {
            actionCode: 'INJECT_SCRIPT',
            urls: [
              baseUrl + '/content-player/content-manager.js',
              baseUrl + '/content-player/non-lti-global_lms.js',
            ],
          },
          '*'
        );
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage, false);
    };
  }, []);

  return (
    <Suspense fallback={<Skeleton />}>
      <iframe
        ref={iframeRef}
        data-id="content-iframe"
        key={key}
        title="Element"
        // scrolling="no"  // Temporary commenting out to unblock frost QA team
        className={styles.iframe}
        id={key}
        onLoad={(e) => {
          if (iframeRef?.current) {
            const allScripts =
              iframeRef.current.contentDocument?.querySelectorAll('script') ||
              [];
            const hasContentManagerScript = [...allScripts].find((script) =>
              script?.src.includes('content-manage')
            );

            const isSeparateHtmlPage =
              iframeRef?.current.src?.includes('.html');

            if (!hasContentManagerScript && isSeparateHtmlPage) {
              const script =
                iframeRef.current.contentDocument?.createElement('script');

              if (!script) {
                return;
              }
              script.src = isDevMode()
                ? '/public/content-management.js'
                : '/suite/content-manager.js';
              iframeRef.current.contentDocument?.body.appendChild(script);

              const link =
                iframeRef.current.contentDocument?.createElement('link');
              if (!link) {
                return;
              }
              link.href = isDevMode()
                ? '/public/style1.css'
                : '/suite/style1.css';
              link.type = 'text/css';
              link.rel = 'stylesheet';
              iframeRef.current.contentDocument?.head.append(link);

              const hasJQuery = [...allScripts].find((script) =>
                script?.src.includes('jquery')
              );

              // non-lti-global_lms.js depends on jQuery
              if (hasJQuery) {
                const scriptLtiGlobal =
                  iframeRef.current.contentDocument?.createElement('script');
                if (!scriptLtiGlobal) {
                  return;
                }
                scriptLtiGlobal.src = isDevMode()
                  ? '/public/non-lti-global_lms.js'
                  : '/suite/non-lti-global_lms.js';
                iframeRef.current.contentDocument?.body.appendChild(
                  scriptLtiGlobal
                );
              }
            }
            iframeRef?.current?.contentWindow?.postMessage(
              {
                type: 'applyStyles',
                payload: {
                  fontFamily: fontFamilyMapping[fontFamily],
                  fontSize:
                    fontSizesMapping?.[fontFamily]?.[fontSize]?.fontSize,
                  lineHeight:
                    fontSizesMapping?.[fontFamily]?.[fontSize]?.lineHeight,
                },
              },
              '*'
            );
          }
        }}
      />
    </Suspense>
  );
};

export default ElementContent;
